import { render, staticRenderFns } from "./InformationsPrecontractuellesWrapper.vue?vue&type=template&id=a50063fe&scoped=true&"
import script from "./InformationsPrecontractuellesWrapper.vue?vue&type=script&lang=ts&"
export * from "./InformationsPrecontractuellesWrapper.vue?vue&type=script&lang=ts&"
import style0 from "./InformationsPrecontractuellesWrapper.vue?vue&type=style&index=0&id=a50063fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50063fe",
  null
  
)

export default component.exports