






import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Action} from "vuex-class";
import applyMixins from "@/utils/mixin/applyMixin";
import {
    ContextAndHeaderManager,
    SimulationContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";

@Component({})
export default class Simulation extends Vue implements ContextAndHeaderManager {

    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;


    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

    created() {
        this.updateHeaderOfPageOnChange();
    }
}

applyMixins(Simulation, [SimulationContextAndHeaderManagerImpl]);

