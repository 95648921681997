



























import {Vue} from "vue-class";
import Component from "vue-class-component";
import {
    ContextAndHeaderManager,
    WalletContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import {Action} from "vuex-class";
import OldInput from "@/components/inputs/old-input.vue";
import EntryDatatable from "@/components/complex-components/entryDatatable.vue";

@Component({
    components: {EntryDatatable, OldInput}
})
export default class Portefeuille extends Vue implements ContextAndHeaderManager {

    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;

    headers = [
        {display: 'N° de dossier'},
        {display: 'Etat'},
        {display: 'Emprunteur'},
        {display: 'Capital attribué'},
        {display: 'Montant 1er achat chaîné'},
        {display: 'Date de saisie'},
        {display: 'Date de financement'},
        {display: 'Barème'},
        {display: 'Canal de saisie'},
    ];

    entries = {
        '49800267517': [
            '$key',
            'Attente de documents',
            'Corinne Berthier',
            '3 000,00€',
            '2070,00€',
            '15/01/2020',
            '',
            'BA',
            'Partenaire',
        ],
        '49800267025': [
            '$key',
            'Attente de documents',
            'Corinne Berthier',
            '3 000,00€',
            '0,00€',
            '15/01/2020',
            '',
            '',
            'Partenaire',
        ],
    };

    suppEntries = {
        '49800267444': [
            '$key',
            'Dossier financé',
            'Théo Dupond',
            '3 000,00€',
            '0,00€',
            '15/01/2020',
            '15/01/2020',
            '',
            'Partenaire',
        ],
        '49800266987': [
            '$key',
            'Attente de documents',
            'Thomas Duquesne',
            '3 000,00€',
            '0,00€',
            '15/01/2020',
            '',
            '',
            'Partenaire',
        ],
        '49800265093': [
            '$key',
            'Dossier financé',
            'Alexandre Maieu',
            '3 000,00€',
            '0,00€',
            '15/01/2020',
            '15/01/2020',
            '',
            'Partenaire',
        ],
    };

    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

    created() {
        this.updateHeaderOfPageOnChange();

        if(!this.$route.query.surname || !this.$route.query.firstname) {
            Object.assign(this.entries, this.suppEntries);
        }
    }

    onRowClicked(payload) {
        let key = payload.key;

        if (key !== null && key !== '') {
            this.$router.push("/portefeuille/" + key + "/details");
        }
    }

}

applyMixins(Portefeuille, [WalletContextAndHeaderManagerImpl]);

