








































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {Action} from "vuex-class";
import File from "@/components/inputs/file.vue";
import ModalSendMail from "@/views/modals/SendMailWrapper.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";
import {assetFile} from "@/utils/misc";
import Foot from "@/components/complex-components/footer.vue";

@Component({
    components: {
        Foot, ModalSendMail, BasicModal, File, Checkbox
    }
})
export default class SignatureElectronique1Wrapper extends Vue {

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addSuccess') addSuccess;

    acceptedConditions = false;

    openMailWrapper = false;

    get isClickable() {
        return this.acceptedConditions;
    }

    onRequestMail() {
        this.openMailWrapper = true;
    }

    onConfirm() {
        if (this.isClickable) {
            this.$router.push('/esign/2');
        }
    }

    onSuccess() {
        this.openMailWrapper = false;
        this.addInfo({message: "Vous serez informé une fois l'email envoyé."}).then();
        setTimeout(() => {
            this.addSuccess({message: "Email envoyé avec succès"}).then();
        }, 2000);
    }

    onClosedMail() {
        this.openMailWrapper = false;
    }

    file(path: string): string {
        return assetFile(path);
    }
}
