<!--suppress CheckTagEmptyBody -->
<template>
    <div id="app">
        <notification-displayer></notification-displayer>
        <div v-if="!(['login'].includes($route.name))">
            <div class="fixed-header">
                <app-header class="gap-between-elements side-margins"></app-header>
                <page-header class="gap-between-elements"></page-header>
            </div>
        </div>
        <div :class="{'below-header' : !(['login'].includes($route.name))}">
            <router-view/>
        </div>
    </div>
</template>

<script>

    import AppHeader from '@/components/headers/AppHeader';
    import PageHeader from '@/components/headers/PageHeader';
    import NotificationDisplayer from '@/components/displayers/notification-displayer';

    export default {
        components: {AppHeader, PageHeader, NotificationDisplayer},

        created() {
            this.$store.commit('setBrowserInfo');
        }
    };

</script>

<style lang="scss">
    @import '../assets/sass/header';

  #app {
      font-family: 'Avenir', Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
  }

  #nav {
      padding: 30px;

      a {
          font-weight: bold;
          color: #2c3e50;

          &.router-link-exact-active {
            color: #42b983;
          }
      }
  }

  .gap-between-elements {
    margin: 16px 0;
  }

    .side-margins {
        margin: 8px;
    }

</style>
