













































































































































































import {Vue} from "vue-class";
import Component from "vue-class-component";
import DefaultInput from "@/components/inputs/default-input.vue";
import Dropdown from "@/components/inputs/dropdown.vue";
import Radio from "@/components/inputs/radio.vue";
import DesignTable from "@/components/complex-components/design-table.vue";
import InformationDisplay from "@/components/displayers/information-display.vue";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import InformationsPrecontractuellesWrapper from "@/views/modals/InformationsPrecontractuellesWrapper.vue";
import InformationsOAAWrapper from "@/views/modals/InformationsOAAWrapper.vue";
import CreditOfferChoice from "@/views/modals/CreditOfferChoice.vue";
import Foot from "@/components/complex-components/footer.vue";

import APRCalculator from "@/utils/APRCalculator";

import {Action, Getter, Mutation} from "vuex-class";
import moment from "moment";
import simulationState from "@/store/modules/subscription/simulationState";
import BasicModal from "@/components/modals/basic-modal.vue";
import Checkbox from "@/components/inputs/checkbox.vue";

@Component({
    components: {
      Checkbox,
        InformationsPrecontractuellesWrapper,
        InformationsOAAWrapper,
        InformationDisplay,
        CreditOfferChoice,
        DesignTable, DropMenuWrapper, DefaultInput, Radio, Dropdown, Foot, BasicModal
    }
})
export default class Simulation extends Vue {
    @Getter('simulationState/isEcheanceEditable') isEcheanceEditable;
    @Getter('simulationState/isScaleWithFee') isScaleWithFee;

    @Mutation('simulationState/setSelectedOffer') setSelectedOffer;
    @Mutation('simulationState/setPurchase') setPurchase;
    @Mutation('simulationState/setCreditPurchase') setCreditPurchase;
    @Mutation('simulationState/setSelectedProduct') setSelectedProduct;
    @Mutation('simulationState/setPurchaseOrder') setPurchaseOrder;
    @Mutation('simulationState/setPurchaseAmount') setPurchaseAmount;
    @Mutation('simulationState/setBringAmount') setBringAmount;
    @Mutation('simulationState/setBringPercent') setBringPercent;
    @Mutation('simulationState/setSelectedScale') setSelectedScale;
    @Mutation('simulationState/setDeadlineDay') setDeadlineDay;
    @Mutation('simulationState/setAPR') setAPR;
    @Mutation('simulationState/setTauxAnnuelDebiteurFixe') setTauxAnnuelDebiteurFixe;
    @Mutation('simulationState/setInputScale') setInputScale;

  // RESET DEFAULT STATE //
    @Mutation('simulationState/resetDefaultState') resetDefaultSimulationState;
    @Mutation('informationState/resetDefaultState') resetDefaultInformationState;
    @Mutation('situationState/resetDefaultState') resetDefaultSituationState;
    @Mutation('incomeAndBankState/resetDefaultState') resetDefaultIncomeAndBankState;
    @Mutation('clientChoiceState/resetDefaultState') resetDefaultClientChoiceState;
    @Mutation('justificatoryState/resetDefaultState') resetDefaultJustificatoryState;

    @Action('informationState/setEmptyBorrowerInformationData') setEmptyBorrowerInformationData;
    @Action('incomeAndBankState/setEmptyBankData') setEmptyBankData;
    @Action('incomeAndBankState/setEmptyIncomeAndBankData') setEmptyIncomeAndBankData;
    @Action('informationState/setEmptyContactInformationData') setEmptyContactInformationData;
    @Action('simulationState/setEmptySimulationData') setEmptySimulationData;
    @Action('situationState/setEmptySituationData') setEmptySituationData;
    @Action('clientChoiceData/setEmptyClientChoiceData') setEmptyClientChoiceData;
    @Action('simulationState/updateScale') updateScale;
    @Action('simulationState/updateEcheance') updateEcheance;
    @Action('simulationState/updateNbEcheance') updateNbEcheance;


    simulation = simulationState.state;

    possibleRadioClientValues = [{label: 'OUI', value: true}, {label: 'NON', value: false}];
    possibleFoundingValues = [{label: 'A crédit', value: true}, {label: 'Au comptant', value: false}];
    firstUse = false;

    slotsManager = new SlotsManagerImpl();

    openOAAWrapper = false;

    openCreditChoice = false;

    isOpen: boolean = true;

    get invalidAmount() {
        let amount = parseInt(this.simulation.purchaseAmount);
        return  !amount || amount < 10;
    }

    get invalidScale() {
        return  !(this.simulation.inputScale !== null && this.simulation.inputScale !=='');
    }

    created() {
        this.simulation.selectedOffer = '';
    }

    createDatatable() {
        let totalAmount: number;
        let totalAmountFee: number;
        let deadlineAmount: number;
        let deadlineAmountFee: number;
        let nbDeadline = parseInt(this.simulation.echeance);
        let lastDeadlineAmount: number;
        let lastDeadlineAmountFee: number;

        if (isNaN(nbDeadline) || nbDeadline == 0)
            nbDeadline = 1;

        // if (!this.isScaleWithFee) {
            totalAmount = parseFloat(this.amount_credit()) * 1.03;
            deadlineAmount = (totalAmount / nbDeadline);
            lastDeadlineAmount = deadlineAmount;
        // } else {
            totalAmountFee = totalAmount * 1.038;
            deadlineAmountFee = (totalAmountFee / nbDeadline);
            lastDeadlineAmountFee = totalAmountFee - ((nbDeadline - 1) * deadlineAmountFee);
        // }
        this.simulation.monthlyPayment = ""+deadlineAmount;

        if (lastDeadlineAmount < 0) {
            deadlineAmount = totalAmount / nbDeadline;
            lastDeadlineAmount = totalAmount / nbDeadline;
        }

        // Set amounts to 0 if NaN
        deadlineAmount = (isNaN(deadlineAmount) ? 0 : deadlineAmount);
        lastDeadlineAmount = (isNaN(lastDeadlineAmount) ? 0 : lastDeadlineAmount);


        let strDeadlineAmount = deadlineAmount.toFixed(2).replace('.', ',');
        let strDeadlineAmountFee = deadlineAmountFee.toFixed(2).replace('.', ',');
        let strLastDeadlineAmount = lastDeadlineAmount.toFixed(2).replace(".", ",");
        let strLastDeadlineAmountFee = lastDeadlineAmountFee.toFixed(2).replace(".", ",");

        let middleNbDeadline = Math.max(nbDeadline - 2, 0).toString();

        let datatable: any[];

        this.simulation.totalAmount = ""+totalAmount;

        if (nbDeadline == 2) {
            datatable = [
                ['', 'Hors assurance', 'Avec Assurance'],
                ['1ère échéance de', strDeadlineAmount + ' €', strDeadlineAmountFee + ' €'],
                ['Suivi de', strLastDeadlineAmount + ' €', strLastDeadlineAmountFee + ' €'],
                ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmountFee.toFixed(2).replace('.', ',') + ' €'],
            ]
        } else if (nbDeadline == 1) {
            datatable = [
                ['', 'Hors assurance', 'Avec Assurance'],
                ['1ère échéance de', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmountFee.toFixed(2).replace('.', ',') + ' €'],
                ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmountFee.toFixed(2).replace('.', ',') + ' €'],
            ]
        } else {
            datatable = [
                ['', 'Hors assurance', 'Avec Assurance'],
                ['1ère échéance de', strDeadlineAmount + ' €', strDeadlineAmountFee + ' €'],
                ['Suivi de', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmount + ' €', middleNbDeadline + ' échéance(s) de ' + strDeadlineAmountFee + ' €'],
                ['et une dernière ajustée de', strDeadlineAmount + ' €', strDeadlineAmountFee + ' €'],
                ['Montant total dû', totalAmount.toFixed(2).replace('.', ',') + ' €', totalAmountFee.toFixed(2).replace('.', ',') + ' €'],
            ]
        }

        return datatable;
    }

    getCurrentDate() {
        let date = moment();

        return date.format("DD/MM/YYYY");
    }

    getFinancementDate() {
        let date = moment();

        date.startOf("month")
                .add(2, "months")
                .add(9, "days");

        return date.format("DD/MM/YYYY");
    }

    updateBringPercent() {
        let purchaseAmount = parseFloat(this.simulation.purchaseAmount);
        let bringAmount = parseFloat(this.simulation.bringAmount);
        let bringPercent = (bringAmount / purchaseAmount) * 100;

        if (isNaN(bringPercent) || !isFinite(bringPercent)) {
            bringPercent = 0;
        }

        let value = (Math.round(bringPercent * 100) / 100).toFixed(2);
        this.setBringPercent(value);
    }

    updateBringAmount() {
        let purchaseAmount = parseFloat(this.simulation.purchaseAmount);
        let bringPercent = parseFloat(this.simulation.bringPercent);
        let bringAmount = (bringPercent / 100) * purchaseAmount;

        if (isNaN(bringAmount) || !isFinite(bringAmount)) {
            bringAmount = 0;
        }

        let value = (Math.round(bringAmount * 100) / 100).toFixed(2);
        this.setBringAmount(value);
    }

    amount_credit() {
        if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount != '') {
            let purchaseAmount = parseFloat(this.simulation.purchaseAmount);
            let bringAmount = parseFloat(this.simulation.bringAmount);

            return (purchaseAmount - bringAmount).toFixed(2);
        } else {
            if (this.simulation.purchaseAmount != '' && this.simulation.bringAmount == '')
                return this.simulation.purchaseAmount;
            else
                return '0.00';
        }
    }

    showOAADoc() {
        this.openOAAWrapper = true;
    }

    closeOAADoc() {
        this.openOAAWrapper = false;
    }

    showCreditChoice() {
        this.openOAAWrapper = false;
        this.openCreditChoice = true;
    }

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextDropMenu() {
        this.slotsManager.incrementTheCounter();
    }

    footerRightCallback() {

        if(this.invalidAmount)
            return false;

        if (this.slotsManager.shownSlot == 1) {
            if (this.simulation.purchase && this.simulation.creditPurchase && parseInt(this.amount_credit()) > 1000) {
                this.showOAADoc();
            } else {
                this.goToNextPage();
            }
        } else if (this.slotsManager.shownSlot == 0 &&
                (!this.simulation.purchase || (this.simulation.purchase && !this.simulation.creditPurchase))) {
            this.goToNextPage();
        } else {
            let duration = parseInt(this.simulation.echeance);
            let totalAmount = parseFloat(this.amount_credit());

            if (!this.isScaleWithFee) {
                this.setAPR(0.00);
                this.setTauxAnnuelDebiteurFixe(0.00)
            } else {
                this.setTauxAnnuelDebiteurFixe(4.793);
                let APR = APRCalculator.calculate(this.simulation.tauxAnnuelDebiteurFixe, duration, totalAmount, this.simulation.fees) * 100;
                this.setAPR(APR.toFixed(3));
            }
            this.goToNextDropMenu();
        }
    }

    goToNextPage() {
        this.openCreditChoice = false;
        this.$emit("nextPage");
    }

    getProductForOAA() {
        if (this.simulation.selectedProduct !== "") {

            let nature = this.simulation.products.filter((nature) => {
                return nature.value == this.simulation.selectedProduct;
            })[0];

            return nature.display;
        }
    }

    onClose() {
        this.isOpen = false;
    }

    onFilled() {
        this.resetDefaultSimulationState();
        this.resetDefaultInformationState();
        this.resetDefaultSituationState();
        this.resetDefaultIncomeAndBankState();
        this.resetDefaultClientChoiceState();
        this.resetDefaultJustificatoryState();
        this.onClose();
    }

    onEmpty() {
        this.setEmptyBorrowerInformationData();
        this.setEmptySimulationData();
        this.setEmptySituationData();
        this.setEmptyContactInformationData();
        this.setEmptyIncomeAndBankData();
        this.resetDefaultJustificatoryState();
        this.setEmptyBankData();
        // ATTENTION : NE PAS EFFACER les données de la page information. Elles seront utilisés pour simuler l'océrisation.
        this.onClose();
    }

    getMonthlyPayment() {
        let totalAmount: number;

        // if (!this.isScaleWithFee) {
        //     totalAmount = parseFloat(this.amount_credit());
        // } else {
            totalAmount = parseFloat(this.amount_credit()) + parseFloat(this.amount_credit()) * (this.simulation.APR / 100);
        // }

        let duration = parseInt(this.simulation.echeance);
        return APRCalculator.monthlyPayment(this.simulation.tauxAnnuelDebiteurFixe, duration, totalAmount + this.simulation.fees)
    }
}
