

















































import {Vue} from "vue-class";
import Component from "vue-class-component";
import File from "@/components/inputs/file.vue";
import {Prop} from "vue-property-decorator";
import ModalSendMail from "@/views/modals/SendMailWrapper.vue";
import BasicModal from "@/components/modals/basic-modal.vue";
import {Action} from "vuex-class";
import {assetFile} from "@/utils/misc";

@Component({
    components: {
        ModalSendMail, BasicModal, File
    }
})
export default class InformationsPrecontractuellesWrapper extends Vue {

    @Action('notifications/addInfo') addInfo;
    @Action('notifications/addSuccess') addSuccess;

    @Prop({default: false})
    noIcon: boolean;

    @Prop({default: 'fa-file'})
    faIcon: string;

    isOpened = false;

    openMailWrapper = false;


    onOpen() {
        this.isOpened = true;
    }

    onRequestMail() {
        this.openMailWrapper = true;
    }

    onClose() {
        this.isOpened = false;
    }

    onSuccess() {
        this.openMailWrapper = false;
        this.addInfo({message: "Vous serez informé une fois l'email envoyé."}).then();
        setTimeout(() => {
            this.addSuccess({message:"Email envoyé avec succès"}).then();
        }, 2000);
    }

    onClosedMail() {
        this.openMailWrapper = false;
    }

    file(path: string): string {
        return assetFile(path);
    }
}

