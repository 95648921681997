import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
/* Vue Clickaway: blur event (use: v-clickaway="() => {}") */
import VueClickaway from 'vue-clickaway';
/* Font Awesome */
import 'font-awesome/css/font-awesome.min.css';

// Vue directives
Vue.directive('clickaway', VueClickaway.directive);

// Vue settings
Vue.prototype.$buildVersion = "1.2.0";
Vue.config.productionTip = false;

// Vue instance
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
