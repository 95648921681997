import getDefaultInformationState from "@/store/modules/subscription/defaultStates/defaultInformationState";
import Vue from "vue";

const state: { coBorrowerSign: boolean, borrower: any, coBorrower: any, contactInformation: any, familySituationsList: Array<object> }
    = getDefaultInformationState();

// getters
const getters = {
    getFamilySituationOfBorrower(state) {
        let found = state.familySituationsList.find(element => element.value == state.borrower.familySituation);

        if (found == null)
            return "";

        return found.display;
    }
};

// actions
const actions = {

    setEmptyBorrowerInformationData(state) {
        let borrowerValues = ['fidelityNumber', 'confirmFidelityNumber', 'identityCardType', 'identityCardNumber',
            'endValidityCard', 'gender', 'name', 'birthName', 'firstName', 'birthDate', 'birthCity', 'zipCode',
            'birthCountry', 'nationality', 'familySituation', 'dependentChildren', 'fixedPhoneNumber',
            'mobilePhoneNumber', 'email'];


        borrowerValues.forEach((key) => {
            state.commit('setBorrower', {key: key, value: ''});
        });
    },

    setEmptyCoBorrowerInformationData(state) {
        let coBorrowerValues = ['fidelityNumber', 'identityCardType', 'identityCardNumber',
            'endValidityCard', 'gender', 'name', 'birthName', 'firstName', 'birthDate', 'birthCity', 'zipCode',
            'birthCountry', 'nationality', 'mobilePhoneNumber', 'email'];

        coBorrowerValues.forEach((key) => {
            state.commit('setCoBorrower', {key: key, value: ''});
        });
    },

    setEmptyContactInformationData(state) {
        let contactInformationValues = ['address', 'complementAddress', 'namePlace', 'zipCode', 'city', 'country'];

        contactInformationValues.forEach((key) => {
            state.commit('setContactInformation', {key: key, value: ''});
        });
    },

};

// mutations
const mutations = {
    setCoBorrowerSign(state, coBorrowerSign) {
        state.coBorrowerSign = coBorrowerSign;
    },
    setBorrower(state, value: { key: string, value: string }) {
        Vue.set(state.borrower, value.key, value.value);
    },
    setCoBorrower(state, value: { key: string, value: string }) {
        state.coBorrower[value.key] = value.value;
    },
    setContactInformation(state, value: { key: string, value: string }) {
        state.contactInformation[value.key] = value.value;
    },
    resetDefaultState(state) {
        Object.assign(state, getDefaultInformationState());
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
