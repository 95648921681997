import {parseScaleToNumberOfDeadLine} from "@/utils/scaleParser";
import getDefaultSimulationState from "@/store/modules/subscription/defaultStates/defaultSimulationState";

const state: {
    offers: Array<any>, selectedOffer: string, purchase: boolean,
    creditPurchase: boolean, products: Array<any>, selectedProduct: string,
    purchaseOrder: string, purchaseAmount: string, bringAmount: string,
    bringPercent: string, scales: Array<any>, selectedScale: string, echeance: string, nbEcheance: string,
    deadlineDay: string, tauxAnnuelDebiteurFixe: number, APR: number, fees: number,
    totalAmount: string, monthlyPayment: string, inputScale: string
} = getDefaultSimulationState();

// getters
const getters = {
    getLabelOfSelectedProduct(state) {
        let result = state.products.find(element => element.value == state.selectedProduct);

        if(result == null)
            return "";
        return result.display;
    },
    isEcheanceEditable(state) {
        return state.selectedScale === "OTHERSCALE" || state.selectedScale === "2-R";
    },
    isScaleWithFee(state) {
        return state.selectedScale === "3-M-P"
            || state.selectedScale === "5-P"
            || state.selectedScale === "10-P"
            || state.selectedScale === "20-P"
            || state.selectedScale === "2-R";
    }
};

// actions
const actions = {
    setEmptySimulationData(state) {
        state.commit('setSelectedOffer', '');
        state.commit('setSelectedProduct', '');
        state.commit('setPurchaseOrder', '');
        state.commit('setPurchaseAmount', '0.00');
        state.commit('setBringAmount', '0.00');
        state.commit('setBringPercent', '0.00');
        state.commit('setSelectedScale', '');
        state.commit('setEcheance', 1);
        state.commit('setNbEcheance', '');
        state.commit('setInputScale', '');
        state.commit('setTauxAnnuelDebiteurFixe', '');
        // state.commit('setDeadlineDay', '');
    },
    updateScale({state, commit}, scale: string) {
        commit('setSelectedScale', scale);

        let newEcheanceByScale = parseScaleToNumberOfDeadLine(state.selectedScale);
        if(newEcheanceByScale != "-1") {
            commit('setEcheance', newEcheanceByScale);
        }
    },
    updateEcheance({state, commit}, echeance: number) {
        commit('setEcheance', echeance);
    },
    updateNbEcheance({state, commit}, nbEcheance: number) {
        commit('setNbEcheance', nbEcheance);
    }
};

// mutations
const mutations = {
    setSelectedOffer(state, offer) {
        state.selectedOffer = offer;
    },
    setPurchase(state, purchase) {
        state.purchase = purchase;
    },
    setCreditPurchase(state, creditPurchase) {
        state.creditPurchase = creditPurchase;
    },
    setSelectedProduct(state, selectedProduct) {
        state.selectedProduct = selectedProduct;
    },
    setPurchaseOrder(state, purchaseOrder) {
        state.purchaseOrder = purchaseOrder;
    },
    setPurchaseAmount(state, purchaseAmount) {
        state.purchaseAmount = purchaseAmount;
    },
    setBringAmount(state, bringAmount) {
        state.bringAmount = bringAmount;
    },
    setBringPercent(state, bringPercent) {
        state.bringPercent = bringPercent;
    },
    setSelectedScale(state, selectedScale) {
        state.selectedScale = selectedScale;
    },
    setEcheance(state, value) {
        state.echeance = value;
    },
    setNbEcheance(state, value) {
        state.nbEcheance = value;
    },
    setDeadlineDay(state, deadlineDay) {
        state.deadlineDay = deadlineDay;
    },
    setAPR(state, value) {
        state.APR = value
    },
    setTauxAnnuelDebiteurFixe(state, value) {
        state.tauxAnnuelDebiteurFixe = value
    },
    resetDefaultState(state) {
        Object.assign(state, getDefaultSimulationState());
    },
    setInputScale(state, inputScale) {
        state.inputScale = inputScale;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
