import { render, staticRenderFns } from "./AvisFavorable.vue?vue&type=template&id=00864ddb&scoped=true&"
import script from "./AvisFavorable.vue?vue&type=script&lang=ts&"
export * from "./AvisFavorable.vue?vue&type=script&lang=ts&"
import style0 from "./AvisFavorable.vue?vue&type=style&index=0&id=00864ddb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00864ddb",
  null
  
)

export default component.exports