



















































import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {SlotsManagerImpl} from "@/typings/componentsExtensions/SlotsManager";
import SectionTitle from "@/components/displayers/section-title.vue";
import Radio from "@/components/inputs/radio.vue";
import {Prop} from "vue-property-decorator";
import SendContractWrapper from "@/views/modals/SendContractWrapper.vue";
import Foot from "@/components/complex-components/footer.vue";
import {namespace} from "vuex-class";

let subscription = namespace('subscriptionState');
let simulation = namespace('simulationState');
@Component({
    components: {Foot, SendContractWrapper, Radio, SectionTitle, DropMenuWrapper}
})
export default class AvisFavorable extends Vue {
    @subscription.State('purchaseAmount') purchaseAmount;
    @simulation.State('selectedOffer') selectedOffer;

    @Prop()
    bundle;

    slotsManager = new SlotsManagerImpl();

    releveDeCompteDematerialise = "";
    releveDeCompteDematerialiseChoices = [{label: "Oui", value: "Yes"}, {label: "Non", value: "No"}];

    get isNotVAC() {
        return this.selectedOffer != "CREDIT";
    }

    onContinue() {
        if(this.purchaseAmount >= 3000)
            this.$router.push("additional-justificatories");
        else
            this.$router.push("/esign/1");
    }

    goToMenu(index: number) {
        this.slotsManager.showTheSlot(index);
    }

    goToNextPage() {
        this.$emit("nextPage");
    }
}

