














import Component from "vue-class-component";
import {Vue} from "vue-class";
import {Prop} from "vue-property-decorator";

@Component({})
export default class BasicModal extends Vue {

    @Prop({default: ''})
    componentStyle: string;

    @Prop({default:false})
    isOpened;

    onModalCloseButtonPressed() {
        this.$emit("close");
    }

}

