import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Souscription from '../views/Souscription.vue';
import Simulation from '../views/Simulation.vue';
import Portefeuille from '../views/Portefeuille.vue';
import Outils from '../views/Outils.vue';
import NotFound from "@/views/404.vue";
import PortefeuilleDetails from "@/views/PortefeuilleDetails.vue";
import SignatureElectronique1Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique1Wrapper.vue";
import SignatureElectronique2Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique2Wrapper.vue";
import SignatureElectronique3Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique3Wrapper.vue";
import SignatureElectronique4Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique4Wrapper.vue";
import SignatureElectronique5Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique5Wrapper.vue";
import SignatureElectronique6Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique6Wrapper.vue";
import SignatureElectronique7Wrapper
    from "@/views/souscription/signature-electronique/SignatureElectronique7Wrapper.vue";
import ReponseDefinitive from "@/views/souscription/ReponseDefinitive.vue";
import PortefeuilleAchat from "@/views/PortefeuilleAchat.vue";
import AdditionalJustificatories from "@/views/AdditionalJustificatories.vue";
import Login from "@/views/Login.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login
    },
    {
        path: "/home",
        name: 'home',
        component: Home
    },
    {
        path: '/souscription',
        name: 'souscription',
        component: Souscription,
    },
    {
        path: '/souscription/:targetPage',
        name: 'souscription-page',
        component: Souscription,
        props: true
    },
    {
        path: '/simulation',
        name: 'simulation',
        component: Simulation
    },
    {
        path: '/portefeuille',
        name: 'portefeuille',
        component: Portefeuille
    },
    {
        path: '/portefeuille/:foldernumber/details',
        name: 'portefeuilleDetails',
        component: PortefeuilleDetails
    },
    {
        path: '/portefeuille/:foldernumber/achat',
        name: 'portefeuilleAchat',
        component: PortefeuilleAchat
    },
    {
        path: '/outils',
        name: 'outils',
        component: Outils
    },
    {
        path: '/esign/1',
        name: 'esign1',
        component: SignatureElectronique1Wrapper
    },
    {
        path: '/esign/2',
        name: 'esign2',
        component: SignatureElectronique2Wrapper
    },
    {
        path: '/esign/3',
        name: 'esign3',
        component: SignatureElectronique3Wrapper
    },
    {
        path: '/esign/4',
        name: 'esign4',
        component: SignatureElectronique4Wrapper
    },
    {
        path: '/esign/5',
        name: 'esign5',
        component: SignatureElectronique5Wrapper
    },
    {
        path: '/esign/6',
        name: 'esign6',
        component: SignatureElectronique6Wrapper
    },
    {
        path: '/esign/7',
        name: 'esign7',
        component: SignatureElectronique7Wrapper
    },
    {
        path: '/reponse-definitive',
        name: 'reponseDefinitive',
        component: ReponseDefinitive
    },
    {
        path: '/additional-justificatories',
        name: 'additional-justificatories',
        component: AdditionalJustificatories
    },
    {
        path: '*',
        component: NotFound
    }
];

const router = new VueRouter({
    routes
});

export default router;
