





















import {Vue} from "vue-class";
import Component from "vue-class-component";
import DropMenuWrapper from "@/components/complex-components/drop-menu-wrapper.vue";
import {Action} from "vuex-class";
import {
    ContextAndHeaderManager,
    SubscriptionContextAndHeaderManagerImpl
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import Simulation from "@/views/souscription/Simulation.vue";
import Informations from "@/views/souscription/Informations.vue";
import Situation from "@/views/souscription/Situation.vue";
import RevenusEtBanque from "@/views/souscription/RevenusEtBanque.vue";
import ChoixClient from "@/views/souscription/ChoixClient.vue";
import Justificatories from "@/views/souscription/Justificatories.vue";
import ReponseDePrincipe from "@/views/souscription/ReponseDePrincipe.vue";
import Breadcrumb from "@/components/complex-components/breadcrumb.vue";
import {SHOP, SUBSCRIPTION} from "@/store/classes/PageHeaderText";
import NavigationAcrossSubscription from "@/components/navigations/navigation-across-subscription.vue";
import {Prop} from "vue-property-decorator";

@Component({
    components: {
        DropMenuWrapper,
        Breadcrumb,
        ReponseDePrincipe,
        ChoixClient,
        RevenusEtBanque,
        Situation,
        Informations,
        Simulation,
        Justificatories,
        NavigationAcrossSubscription
    },
    watch: {
        currentTextHeader: {
            immediate: true,
            handler(newVal, oldVal) {
                this.updateHeaderOfPageOnChange(newVal)
            }
        }
    }
})
export default class Souscription extends Vue implements ContextAndHeaderManager {
    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;

    bundle = {};

    @Prop({default: 0})
    targetPage;

    currentPage = 0;

    currentTextHeader = SUBSCRIPTION;

    pages = [
        {
            title: 'Simulation',
            icon: 'img/simulation.png',
            template: 'simulation',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'justificatories',
            header: SUBSCRIPTION
        },
        {
            title: 'Informations',
            icon: 'img/informations.png',
            template: 'informations',
            header: SUBSCRIPTION
        },
        {
            title: 'Situation',
            icon: 'img/situation.png',
            template: 'situation',
            header: SUBSCRIPTION
        },
        {
            title: 'Revenus et banque',
            icon: 'img/revenus-et-banque.png',
            template: 'revenus-et-banque',
            header: SUBSCRIPTION
        },
        {
            title: 'Choix client',
            icon: 'img/choix-client.png',
            template: 'choix-client',
            header: SHOP
        },
        {
            title: 'Réponse de principe',
            icon: 'img/reponse-de-principe.png',
            template: 'reponse-de-principe',
            header: SHOP
        },
    ];

    updateHeaderOfPageOnChange: (textForHeader?: string) => void;

    created() {
        this.updateHeaderOfPageOnChange(this.currentTextHeader);
        this.currentPage = this.targetPage;
    }

    onNextButtonPressed(bundle) {
        this.bundle = bundle;

        // this.currentPage = (this.currentPage + 1) % this.pages.length;

        if (this.currentPage >= (this.pages.length - 1)) {
            this.$router.push('/esign/1');
        } else {
            this.currentPage++;
        }
    }

    goToPage(index: number) {
        this.currentPage = index;
    }

}

applyMixins(Souscription, [SubscriptionContextAndHeaderManagerImpl]);

