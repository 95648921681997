







































































import '../../assets/sass/app.scss';
import '../../assets/sass/home.scss';

import DefaultInput from "@/components/inputs/default-input.vue";

import {Vue} from "vue-class";
import Component from "vue-class-component";
import MenuButton from "@/components/inputs/menu-button.vue";
import {assetImg} from "@/utils/misc";
import {Action, Getter, Mutation} from "vuex-class";
import BaseValidator from "@/validation/BaseValidator";
import {DateRule, RequiredRule} from "@/validation/rules";
import {ZipCodeRule} from "@/validation/rules/ZipCodeRule";
// noinspection TypeScriptCheckImport
import Datepicker from 'vuejs-datepicker';
// noinspection TypeScriptCheckImport
import {fr} from 'vuejs-datepicker/dist/locale'
import {
    BasicContextAndHeaderManagerImpl,
    ContextAndHeaderManager
} from "@/typings/componentsExtensions/ContextAndHeaderManager";
import applyMixins from "@/utils/mixin/applyMixin";
import {PositiveIntegerRule} from "@/validation/rules/PositiveIntegerRule";

@Component({
    components: {MenuButton, Datepicker, DefaultInput}
})
export default class Home extends Vue implements ContextAndHeaderManager {

    /* getters */
    @Getter('validation/hasNoErrors') hasNoErrors;
    @Getter('canUseGridDisplay') canUseGridDisplay;

    /* actions */
    @Action('validation/addValidator') addValidator;
    @Action('notifications/addError') addError;
    @Action('htmlFeatures/changeContextAndHeaderOfPage') changeContextAndHeaderOfPage;

    /* mutations */
    @Mutation('validation/addValidatorGroupToShouldDisplayErrorMessage') addValidatorGroupToShouldDisplayErrorMessage;
    @Mutation('validation/removeValidatorGroupFromShouldDisplayErrorMessage') removeValidatorGroupFromShouldDisplayErrorMessage;


    menus: Array<{ icon: string, label: string, path: string }> =
        [
            {
                icon: 'souscription.png',
                label: 'SOUSCRIPTION',
                path: '/souscription'
            },
            {
                icon: 'simulation.png',
                label: 'SIMULATION',
                path: '/home'
            },
            {
                icon: 'portefeuille.png',
                label: 'PORTEFEUILLE',
                path: '/portefeuille'
            },
            {
                icon: 'outils.png',
                label: 'OUTILS',
                path: '/home'
            },
        ];

    firstname: string = '';
    lastname: string = '';
    birthdate: string = '';
    zipcode: string = '';
    foldernumber: string = '';

    data() {
        return {
            fr: fr
        };
    }

    updateHeaderOfPageOnChange: (textForHeader?: string) => null;

    menuHomeCss= "menu-home-with-grid";
    menuBtn="menu-btn";

    created() {
        this.updateHeaderOfPageOnChange();

        if(!this.canUseGridDisplay) {
            this.menuHomeCss = "menu-home-without-grid";
            this.menuBtn = "menu-btn-without-grid";
        }

        // Home Filter Client Validators
        this.addHomeFilterClientValidator("firstname", this.firstname, [new RequiredRule()]);
        this.addHomeFilterClientValidator("lastname", this.lastname, [new RequiredRule()]);
        this.addHomeFilterClientValidator("birthdate", this.birthdate, [new DateRule()]);
        this.addHomeFilterClientValidator("zipcode", this.zipcode, [new ZipCodeRule()]);

        // Home Filter Folder Number Validators
        this.addHomeFilterFolderNumberValidator("foldernumber", this.foldernumber, [new RequiredRule(), new PositiveIntegerRule()]);
    }


    addHomeFilterClientValidator(property, value, rules = []) {
        return this.addValidator({
            instance: this,
            validatorGroup: 'homeFilterClient',
            property: property,
            validator: new BaseValidator(rules, value)
        })
    }

    addHomeFilterFolderNumberValidator(property, value, rules = []) {
        return this.addValidator({
            instance: this,
            validatorGroup: 'homeFilterFolderNumber',
            property: property,
            validator: new BaseValidator(rules, value)
        })
    }

    onSearchButtonPressed(element) {
        element.preventDefault();

        if (this.hasNoErrors('homeFilterFolderNumber')) {
            this.removeValidatorGroupFromShouldDisplayErrorMessage("homeFilterFolderNumber");

            this.nextWithFolderNumber();

            return;
        } else if (this.hasNoErrors('homeFilterClient')) {
            this.removeValidatorGroupFromShouldDisplayErrorMessage("homeFilterClient");

            this.nextWithClient();

            return;
        } else {
            this.addValidatorGroupToShouldDisplayErrorMessage("homeFilterClient");
            this.addValidatorGroupToShouldDisplayErrorMessage("homeFilterFolderNumber");
            this.addError({message: "Certaines données ne sont pas valides. Veuillez les vérifier."});

            return;
        }
    }

    nextWithFolderNumber() {
        this.$router.push('/portefeuille/' + this.foldernumber + '/details');
    }

    nextWithClient() {
        this.$router.push({path: '/portefeuille', query: {firstname: 'Corinne', surname: 'Berthier'}});
    }

    image(path: string) {
        return assetImg(path);
    }

    onDemo (label) {
        if (label === 'OUTILS' || label === 'SIMULATION'){
            this.addError({message: "Non accessible dans la version démo."});

            return;
        }
    }

}

applyMixins(Home, [BasicContextAndHeaderManagerImpl]);

