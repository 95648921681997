export default () => {
    return {
        coBorrowerSign: false,
        borrower: {
            fidelityNumber: '1234567890123456789',
            confirmFidelityNumber: '1234567890123456789',
            identityCardType: 'CNI',
            identityCardNumber: '9400992310285',
            endValidityCard: '2023-05-20',
            gender: 'MR',
            name: 'Test',
            birthName: 'Test',
            firstName: 'Test',
            birthDate: '1975-06-06',
            birthCity: 'Paris',
            zipCode: '75001',
            birthCountry: 'FR',
            nationality: 'FR',
            familySituation: 'married',
            dependentChildren: '0',
            fixedPhoneNumber: '06 07 08 09 10',
            mobilePhoneNumber: '06 07 08 09 10',
            email: 'test.test@gmail.com'
        },
        coBorrower: {
            fidelityNumber: '987654321',
            identityCardType: 'CNI',
            identityCardNumber: '080985202435',
            endValidityCard: '2021-08-06',
            gender: 'MR',
            name: 'DUBREUIL',
            birthName: 'DUBREUIL',
            firstName: 'PIERRE, ERIC',
            birthDate: '1959-02-23',
            birthCity: 'SAINGHIN EN WEPPES',
            zipCode: '59184',
            birthCountry: 'FR',
            nationality: 'FR',
            mobilePhoneNumber: '06 08 53 67 01',
            email: 'pe.dubreuil@gmail.com'
        },
        contactInformation: {
            address: '11 RUE JEAN MERMOZ',
            complementAddress: '',
            namePlace: '',
            zipCode: '76000',
            city: 'SAINT-ETIENNE-DU-ROUVRAY',
            country: 'FR'
        },
        familySituationsList: [
            {display: "Célibataire", value: "single"},
            {display: "Divorcé(e)", value: "divorced"},
            {display: "Marié(e)", value: "married"},
            {display: "Pacsé(e)", value: "pacs"},
            {display: "Séparé(e)", value: "separate"},
            {display: "En couple", value: "relationship"},
            {display: "Veuf(veuve)", value: "widow"}
        ]
    }
}
