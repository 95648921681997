export default () => {
    return {
        offers: [
            {display: 'CR Sofinco Partner', value: 'RENOUV'},
            {display: 'VAC EM HORS HABITAT', value: 'CREDIT'}
        ],
        selectedOffer: '',
        purchase: true,
        creditPurchase: true,
        products: [
            {display: 'Bricolage', value: 'DIY'},
            {display: 'Chauffage climatisation', value: 'AIRCONDITIONING'},
            {display: 'Cuisine équipée', value: 'EQUIPPEDKITCHEN'},
            {display: 'Cycle', value: 'CYCLE'},
            {display: 'Divers egm', value: 'EGM'},
            {display: 'Divers image', value: 'PICTURES'},
            {display: 'Electroménager', value: 'HOMEAPPLIANCE'},
            {display: 'Equipement de la maison', value: 'HOMEEQUIPMENT'},
            {display: 'Equipement professionnel', value: 'PROFESSIONALEQUIPMENT'},
            {display: 'Fenêtres', value: 'WINDOWS'},
            {display: 'Isolation', value: 'INSULATION'},
            {display: 'Matériel informatique', value: 'HARDWARE'},
            {display: 'Meuble', value: 'FURNITURE'},
            {display: 'Motoculture', value: 'MOTORCULTURE'},
            {display: 'Musique (hors piano)', value: 'MUSIC'},
            {display: 'Panneaux solaires', value: 'SOLARPANELS'},
            {display: 'Piano', value: 'PIANO'},
            {display: 'Piscine', value: 'SWIMMINGPOOL'},
            {display: 'Ravalement', value: 'SWALLOWING'},
            {display: 'Sécurité, alarmes', value: 'ALARM'},
            {display: 'Traitement charpente', value: 'CARPENTTREATMENT'},
            {display: 'Traitement de l\'air', value: 'AIRTREATMENT'},
            {display: 'Traitement de l\'eau', value: 'WATERTREATMENT'},
            {display: 'Véranda', value: 'VERANDA'},
        ],
        selectedProduct: "DIY",
        purchaseOrder: '',
        purchaseAmount: '1000.00',
        bringAmount: '0.00',
        bringPercent: '0.00',
        totalAmount: '0.00',
        scales: [
            // {display: '3 mois payant', value: '3-M-P'},
            // {display: '5 fois payant', value: '5-P'},
            // {display: '10 fois payant', value: '10-P'},
            // {display: '20 fois payant', value: '20-P'},
            // {display: '3 fois gratuit', value: '3-F'},
            // {display: '5 fois gratuit', value: '5-F'},
            // {display: '10 fois gratuit', value: '10-F'},
            // {display: '20 fois gratuit', value: '20-F'},
            // {display: 'Report 2 mois', value: '2-R'},
            {display: 'Autre barème', value: 'OTHERSCALE'}
        ],
        selectedScale: 'OTHERSCALE',
        inputScale: 'FORFGD3E',
        echeance: "10",
        nbEcheance: "10",
        deadlineDay: '10',
        tauxAnnuelDebiteurFixe: 6.492,
        APR: 6.689,
        fees: 0.0,
        monthlyPayment: "0.00"
    }
}
