








































import {Vue} from "vue-class";
import Component from "vue-class-component";
import {assetImg} from '@/utils/misc';
import DefaultInput from "@/components/inputs/default-input.vue";

@Component({
    components: {DefaultInput}
})

export default class Login extends Vue {

    login = "";
    password = "";
    displayCard = true;

    loginErrorMessage = false;

    defaultAccount = {email: 'smart.phone@ingka.bricotdepot.com', password: 'BRICO2021'};

    getImage(path) {
        return assetImg(path)
    }

    nextPage() {
        this.$router.push('/');
    }

    selectAccount(value) {
        if (value)
            this.login = this.defaultAccount.email;

        this.displayCard = false
    }

    logIn() {
        let valid = this.password != '' && this.login != '';
        if (valid)
            this.$router.push('/home');
        else
            this.loginErrorMessage = true;
    }
}

